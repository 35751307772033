import React, { useEffect, useCallback, useRef, useState, ReactElement } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import IconButton from '@material-ui/core/IconButton';
import UseMyLocation from '@material-ui/icons/MyLocationOutlined';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';

export interface PlaceType {
  place_id: string;
  description: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: [
      {
        offset: number;
        length: number;
      }
    ];
  };
}

const useIconStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

const useProgressStyles = makeStyles({
  colorPrimary: {
    backgroundColor: 'rgb(var(--secondary))',
  },
  barColorPrimary: {
    backgroundColor: 'rgb(var(--tertiary))',
  },
});

type LocationAutocompleteProps = {
  autocompleteService: google.maps.places.AutocompleteService;
  onSelect(place: PlaceType | null): void;
  initValue?: PlaceType | null;
  className: string;
  onUseMyLocation(coords: GeolocationCoordinates): void;
};

export default function LocationAutocomplete({
  autocompleteService,
  onSelect,
  onUseMyLocation,
  initValue,
  className,
  ...props
}: LocationAutocompleteProps): ReactElement {
  const iconClasses = useIconStyles();
  const progressClasses = useProgressStyles();
  const [value, setValue] = useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<PlaceType[]>([]);
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const debounceTimeout = useRef<null | NodeJS.Timeout>(null);

  const useMyLocation = (): void => {
    const currentPositionTimeout = setTimeout(locationError, 10000);
    function locationSuccess(position: GeolocationPosition): void {
      clearTimeout(currentPositionTimeout);
      onUseMyLocation(position.coords);
      setIsLoading(false);
    }

    function locationError(): void {
      clearTimeout(currentPositionTimeout);
      setError(
        'There was an issue finding your location. Please enable your device to allow using your location.'
      );
      setIsLoading(false);
    }

    setError('');
    setIsLoading(true);
    navigator.geolocation.getCurrentPosition(locationSuccess, locationError);
  };

  const getPredictions = useCallback(
    (request, callback) => {
      if (debounceTimeout.current !== null) clearTimeout(debounceTimeout.current);
      debounceTimeout.current = setTimeout(() => {
        autocompleteService.getPlacePredictions(request, callback);
      }, 200);
    },
    [autocompleteService]
  );

  useEffect(() => {
    setValue(initValue || null);
  }, [initValue]);

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    getPredictions(
      {
        input: inputValue,
        componentRestrictions: {
          country: 'us',
        },
      },
      (results?: PlaceType[]) => {
        if (active) {
          let newOptions = [] as PlaceType[];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, getPredictions]);

  const renderOption = (option: PlaceType): ReactElement => {
    const matches = option.structured_formatting.main_text_matched_substrings;
    const parts = parse(
      option.structured_formatting.main_text,
      matches.map((match) => [match.offset, match.offset + match.length])
    );

    return (
      <Grid container alignItems="center">
        <Grid item>
          <LocationOnIcon className={iconClasses.icon} />
        </Grid>
        <Grid item xs>
          {parts.map((part, index) => (
            <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
              {part.text}
            </span>
          ))}
          <Typography variant="body2" color="textSecondary">
            {option.structured_formatting.secondary_text}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderInput = (params: AutocompleteRenderInputParams): ReactElement => (
    <TextField
      className="shadow-lg"
      {...params}
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <IconButton
              color="primary"
              aria-label="use my location"
              component="span"
              onClick={useMyLocation}
            >
              <UseMyLocation className="text-tertiary-actual" />
            </IconButton>
          </InputAdornment>
        ),
        className: 'p-3',
        disableUnderline: true,
      }}
      placeholder="Search by city, state, or zipcode"
      variant="standard"
      fullWidth
    />
  );

  return (
    <div className={className}>
      <Autocomplete
        {...props}
        id="locations-autocomplete"
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        value={value}
        disabled={isLoading}
        filterSelectedOptions
        onChange={(event: React.ChangeEvent<unknown>, newValue: PlaceType | null) => {
          setError('');
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          onSelect(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={renderInput}
        renderOption={renderOption}
      />
      {isLoading && (
        <LinearProgress
          classes={{
            colorPrimary: progressClasses.colorPrimary,
            barColorPrimary: progressClasses.barColorPrimary,
          }}
        />
      )}
      {error && <div className="my-2 px-5 text-red">{error}</div>}
    </div>
  );
}
